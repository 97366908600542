import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import ContactUs from '../Contactus/ContactUs';
import './navcontact.css';
import { BsFacebook } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { AiTwotoneMail } from 'react-icons/ai';
import React from 'react'

export default function NavContact() {
    return (
        <>
            <NavBar />
            <hr />
            <ContactUs />

            <div className='c-map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15554.823407517486!2d77.6646148!3d12.9266175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1364228a0c93%3A0xf00a70cb73b0d65f!2sSCOPE%20TUITION!5e0!3m2!1sen!2sca!4v1694713484189!5m2!1sen!2sca" width="100%" height="450" style={{ border: '0', color: '#0000FF' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <h1 style={{ color: 'white', textAlign: 'center' }}>Get in touch</h1>
            <div className='n-icons' >
                <a href="https://www.facebook.com/people/Scope-Personalized-Tuition/100065204371491/"><BsFacebook size={30} color='white' /></a>
                <a href="https://www.youtube.com/@scopeedification5844"><BsYoutube size={30} color='white' /></a>
                <a href="mailto:mailbox.garima@gmail.com"><AiTwotoneMail size={30} color='white' /></a>
            </div>
        </>
    )
}

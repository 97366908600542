import './benifits.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react'
import { GiTeacher } from 'react-icons/gi';
import { FcPortraitMode } from 'react-icons/fc'
import { FcApproval } from 'react-icons/fc'
export default function Benifits() {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
        <br>
        </br> <br></br>
            <div className='b-content'>
                <span className='b-heading'>
                <h1>Benifits</h1>
                <h4>Exceeding Expectations</h4>
                </span>
                <span className='b-heading'>
                    <h3>Get the Best Teacher</h3>
                    <p>Online tuition classes eliminate the limitation of choosing teachers only from the surrounding area. Students now have the opportunity to connect with top tutors from different locations. At SCOPE we carefully select the best tutors from across the world.</p>
                </span>
                <br></br>
                <span className='b-heading'>
                    <h3>Saves Time </h3>
                    <p>Online tuition can save plenty of time; since not many students are there to create chaos like offline tuitions & the student also saves travel time.</p>
                </span>
                <br></br>
                <span className='b-heading'>
                    <h3>Easily Accessible Study Material</h3>
                    <p>Online tuition classes eliminate the limitation of choosing teachers only from the surrounding area. Students now have the opportunity to connect with top tutors from different locations. At SCOPE we carefully select the best tutors from across the world.</p>
                </span>
                <br></br>
                <span className='b-heading'>
                    <h3>Parent Supervision</h3>
                    <p>Online tuition allows parents to easily supervise their children's progress and identify any challenges they may face in their studies. With classes held at home, parents can closely monitor their children's learning and encourage them to excel. Online classes take place in the comfort of their home.</p>
                </span>
                <br></br>
                <span className='b-heading'>
                    <h3>Comes with Doubt Clearing Sessions</h3>
                    <p>One more reason why online tuition has an advantage is that; after the learning session, the student is also provided with a “Doubt clearing session”, where students can go through topics they were not able to understand during the learning session.</p>
                </span>
                <br></br>
                <span className='b-heading last'>
                    <h3>Online Learning is the Future</h3>
                    <p>With the growing competition , everybody wishes to have a quality education to be able to perform better in any field.Online learning is becoming very relevant in such a stage because people now realize how important it is to make productive use of time in a cost-effective way.Availability of teacher on demand specially during exam time(saves Travel time,Friends Distraction and much more ) </p>
                </span>
                <br></br> <br></br>
            </div>
        </>
    )
}

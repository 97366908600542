import React from 'react'
import './footer.css';
import { BsYoutube } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { AiTwotoneMail } from 'react-icons/ai';
import { PiStudentFill } from 'react-icons/pi';
export default function Footer() {
  return (
    <>
      <div className='footer'>
        <img src='./images/wave.png' style={{ width: '100%', marginTop:'-10px'}}></img>
        <div className='f-content'>
          <div className='title'>
            <PiStudentFill size={40} /> SCOPE <br />Personalized Tuition 
          </div>

          <div className='f-icons'>
            <a href="https://www.facebook.com/people/Scope-Personalized-Tuition/100065204371491/"><BsFacebook size={30} /></a>
            <a href="https://www.youtube.com/@scopeedification5844"><BsYoutube size={30} /></a>
            <a href="mailto:mailbox.garima@gmail.com"><AiTwotoneMail size={30} /></a>
          </div>
          <div style={{ marginBottom: '0px' }}>
            <i>&copy;copyright 2023 scope tution center</i>
            {/* <i>--designed by shilpa</i> */}
          </div>
        </div>
      </div>
    </>
  )
}

import BackImage from '../BackImage/BackImgae';
import Benifits from '../Benifits/Benifits';
import ContactUs from '../Contactus/ContactUs';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import SampleVideos from '../SampleVideos/SampleVideos';
import Video from '../Video/Video';


export default function Main() {
    return (
        <>
            <NavBar />
            <BackImage />
            <Video />
            <SampleVideos />
            <Benifits />
            <ContactUs />
            <Footer />
        </>
    );
}
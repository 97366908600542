import React from 'react'
import './samplevideos.css';
export default function SampleVideos() {
  return (
    <>

      <br></br>
      <span className="span-feedback"style={{ color: 'white' }}> <h2> Watch Feedback Video From Students and Parents</h2> </span>
      <section id='sample-video'>
        <div className='p-container'>

          <div className='all-video'>

            <div className='itemOne'>
              <div className='left'>
                <h2 className='project-title'>Feedback</h2>
                <div className='img'>
                  <iframe src="https://www.youtube.com/embed/ZjATckzmttA?si=PAyakPEV-OsRqLC5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{width:'450px'}}></iframe>
                  <p className='project-desc'></p>
                </div>
              </div>
            </div>

            <div className='v-heading'>
              <h1>FeedBack</h1>
              <h3>From Parents And Students</h3>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}




// export default function SampleVideos() {
//   return (
//     <div className='container'>
//       <div className="row">
//         <div className="col-sm-5">
//           <div className="card" style={{ width: "18rem;", backgroundColor: 'white' }}>
//             <div className="card-body">
//               <h5 className="card-title" style={{ color: 'white' }}>Special title treatment</h5>
//               <iframe width="460" height="315" src="https://www.youtube.com/embed/V-dwsP-NwYA?si=-iDawXxB8bdo8E9W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//               <a href="#" className="btn btn-primary">Go somewhere</a>
//             </div>
//           </div>
//         </div>
//         <div className="col-sm-5">
//           <div className="card" style={{ width: "18rem;", backgroundColor: 'white' }}>
//             <div className="card-body">
//               <h5 className="card-title" style={{ color: 'white' }}>Special title treatment</h5>
//               <iframe width="460" height="315" src="https://www.youtube.com/embed/V-dwsP-NwYA?si=-iDawXxB8bdo8E9W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//               <a href="#" className="btn btn-primary">Go somewhere</a>
//               <a href="#" className="btn btn-primary">Go somewhere</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//     // <div className="row">
//     //   <div className="col-sm-6">
//     //     <div className="card" style={{ width: "18rem;", backgroundColor: 'white' }}>
//     //       <div className="card-header">
//     //         <h1 style={{ color: 'white' }}>Feedback from Students</h1>
//     //       </div>
//     //       <iframe width="460" height="315" src="https://www.youtube.com/embed/V-dwsP-NwYA?si=-iDawXxB8bdo8E9W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//     //       <div className="card-body">
//     //         <h5 className="card-title">Card title</h5>
//     //         <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//     //         <a href="#" className="btn btn-primary">Go somewhere</a>
//     //       </div>
//     //     </div>
//     //   </div>

//     //   <div className="col-sm-6">
//     //     <div className="card" style={{ width: "18rem;", backgroundColor: 'white' }}>
//     //       <div className="card-header">
//     //         <h1 style={{ color: 'white' }}>Feedback from Students</h1>
//     //       </div>
//     //       <iframe width="460" height="315" src="https://www.youtube.com/embed/V-dwsP-NwYA?si=-iDawXxB8bdo8E9W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//     //       <div className="card-body">
//     //         <h5 className="card-title">Card title</h5>
//     //         <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//     //         <a href="#" className="btn btn-primary">Go somewhere</a>
//     //       </div>
//     //     </div>
//     //   </div>

//     // </div>

//   )
// }

import React from 'react';
import './backimage.css';
import { GiReed } from 'react-icons/gi';

export default function BackImage() {

    return (
        <>
            <div className='banner-img' style={{ backgroundImage: `url(./images/books.webp)` }}>
            </div>
            <div className='banner-text'>
                <h1>BEST <br></br>ONLINE</h1>
                <h2 style={{}}>TUITION</h2>
                <h3>THE BEST TUTOR ARE NOW WITHIN YOUR REACH</h3>

                <div className='demo-div'>
                    <h4>Contact Us today for a Demo class <br></br>Click on the <span style={{ color: 'green' }}> WhatsApp Link</span></h4>
                    <div className='demo-box'>
                        <span id='d'>D</span>
                        <span id='e'>E</span>
                        <span id='m'>M</span>
                        <span id='o'>O</span>
                    </div>
                    <span className='facebook-icon'>
                        <a href="https://www.facebook.com/profile.php?id=100065204371491&mibextid=ZbWKwL"><img src="./images/fb.webp" alt="" width={50} /></a>
                    </span>
                </div>
            </div>
        </>
    )
}

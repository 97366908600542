import './letschat.css';

import React from 'react'


export default function LetsChat() {
  return (
    <div className='whatsapp'>
        {/* below link is from click to chat official whatsapp website */}
       <a href="https://wa.me/919972703500">
        <img src="./images/whatsapp.webp" width={60}/>
       </a>
    </div>
  )
}

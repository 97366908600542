import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './Main/Main';
import AboutUs from './AboutUs/AboutUs';
import NavContact from './NavContact/NavContact';
import LetsChat from './LetsChat/LetsChat';
import SampleVideos from './SampleVideos/SampleVideos';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/AboutUs' element={<AboutUs/>} />
          <Route path='/ContactUs' element ={<NavContact/>} />
          <Route path='Videos' element={<SampleVideos/>}/>
        </Routes>
      </BrowserRouter>
      <LetsChat/>
    </>
  );
}



import React , {useEffect} from 'react'
import './video.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AiOutlineArrowRight } from 'react-icons/ai';

export default function Video() {
  useEffect(() => {
    AOS.init();
}, [])

  return (
    <div className='video'>
      <div className='v-heading'>
        <h1>To Know About Scope <br /> Personalized Tuition</h1>
        <h2>Watch this video<AiOutlineArrowRight size={30} /></h2>
      </div>
      <div className='y-video' data-aos="fade-right">
        <div className='y-link'>
          <iframe width="460" height="315" src="https://www.youtube.com/embed/V-dwsP-NwYA?si=-iDawXxB8bdo8E9W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        <div className='y-link' data-aos="fade-left">
          <iframe width="460" height="315" src='https://www.youtube.com/embed/Icl-W_OTWrI?si=xI79g2PQ282-8FKf' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react';
import './navbar.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import {PiStudentFill} from 'react-icons/pi';

export default function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <nav>
            <div className='title'><PiStudentFill size={40}/> SCOPE Personalized Tuition</div>
            <div className='menu' onClick={() => {
                setMenuOpen(!menuOpen)
            }}>
                <GiHamburgerMenu size={30} />
            </div>
            <ul style={{ listStyleType: 'none' }} className={menuOpen ? "open" : "NoStyle"}>
                <a href="/"><li>Home</li></a>
                {/* <a href='Videos'><li>Sample Videos</li></a> */}
                <a href="AboutUs"><li>About Us</li></a>
                <a href="ContactUs"><li>Contact Us</li></a>
            </ul>
        </nav>
    );
}

import React from 'react'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import './about.css';


export default function AboutUs() {
    return (
        <div>
            <NavBar />
            <hr />
            <div className='about'>
                <div className='a-disc'>
                    <h2>About Us</h2>
                    <p>We are a leading online learning platform, delivering access to our high quality videos to everyone with an internet connection. Our content provides engaging and interesting learning opportunities, guiding viewers to identify their strengths and fill in their learning gaps. When you participate in our Online+Tutor, you’re joining a global community of like-minded individuals looking to expand their understanding.</p>
                </div>
                
                <div className='a-disc'>
                    <h1>Founder's Story</h1>
                    <img src='./images/three.webp' className='a-image'></img>
                    <p>Hello, I am Garima Mishra. After Spending more than a Decade teaching on Different Schools & Boards, I have started my Tutorial "SCOPE" in 2016 for Students from standard 1st to standard 12th for all Subjects.<br /><br />
                        I hold a Master’s Degree in Organic chemistry (M.Sc.) & am also holding Bachelor’s Degree in Education (B.Ed.),<br />
                        I am supported by 15 more teachers currently and overall we have 300+ Years of teaching experience,<br /><br />
                        Till 2019 we were only conducting offline in-classNameroom sessions. However, 2020 made us also go online !!<br /><br />
                        Our Team provides engaging and interesting learning opportunities to students around the world, guiding them to <br /> identify their strengths and fill in their learning gaps. When you participate in our Online+Tutor sessions,<br /> you’re embarking on a journey of discovery and understanding.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

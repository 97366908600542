import React, { useState } from 'react';
import { useRef } from 'react';
import { ImLocation2 } from 'react-icons/im';
import { AiTwotoneMail } from 'react-icons/ai';
import { HiPhoneMissedCall } from 'react-icons/hi';
import './contact.css';
import emailjs from '@emailjs/browser';

export default function ContactUs() {
    // from emailjs- react page
    const form = useRef();
    // to display message on sending the message
    const [done, setDone] = useState(false);
    const sendEmail = (e) => {
        console.log("sendingemail");
        e.preventDefault();
        // service-id, template-id and public key is copied from my  emailjs account.
        emailjs.sendForm('service_lvk9mtq', 'template_ztnreq9', form.current, 'fPoE9HS6uAfoItSpJ')
            .then((result) => {
                console.log(result.text);
                setDone(true);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div>
            <div className='contact'>
                <div className='reach-us'>
                    <h1>Contact Us</h1>
                    <p><ImLocation2 size={25} />
                        SCOPE Tuition, Suncity Apartments, Sarjapur ORR <span className='contact-us-place'> Bengaluru, </span> <span className='contact-span'> 560102</span> </p>
                    <p><AiTwotoneMail size={25} /> <a href="mailto:mailbox.garima@gmail.com" style={{ color: 'white', textDecoration: 'none' }}> mailbox.garima@gmail.com</a></p>
                    <p><HiPhoneMissedCall size={25} /> <span>+91-9972703500</span></p>
                </div>

                <div className='c-right'>
                    {/* install emailjs by -- npm add @emailjs/browser to send the info to my gmail */}
                    <form ref={form} onSubmit={sendEmail}>
                        {/* below name of the input must match with the names in the email template of emailjs(from_name, user_email and message) */}
                        <input type='text' name='from_name' className='user' placeholder='Enter your name' />
                        <input type='text' name='phone_number' className='user' placeholder='Enter your Phone number' />
                        <input type='text' name='user_email' className='user' placeholder='Enter your Email address' />
                        <textarea name='message' className='user' placeholder='message'></textarea>
                        <br />
                        <input type='submit' value='send message' className='button' />
                        <span>{done && "Thanks for contacting Us"}</span>
                    </form>
                </div>

                {/* <div className='c-form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <input type='text' name='from_name' className='user' placeholder='Enter your name' />
                        <input type='text' name='user_email' className='user' placeholder='Enter your Phone number' />
                        <input type='text' name='phone_number' className='user' placeholder='Enter your Email address' />
                        <textarea name='message' className='user' placeholder='message' />
                        <input type='submit' value='send message' className='f-btn' style={{height:'30px', marginTop:'10px'}}/>
                        <span>{done && "Thanks for contacting me"}</span>
                    </form>
                </div> */}
                {/* <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15554.823407517486!2d77.6646148!3d12.9266175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1364228a0c93%3A0xf00a70cb73b0d65f!2sSCOPE%20TUITION!5e0!3m2!1sen!2sca!4v1693500817088!5m2!1sen!2sca" width="430" height="350" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div> */}
            </div>
        </div>
    )
}
